import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import contactUsServices from '../../../api/contactUsServices';
import {
  getStoreAuthenticityToken,
  getStoreCheckEmailDomain,
  getStoreContactUsDetails,
  getStoreLoading,
  getStoreUserDetails
} from '../../ServiceLayer';

export const fetchContactUsInitialData = createAsyncThunk(
  'contactUs/getInitialData',
  (product) => contactUsServices.getContactUsInitialisation(product)
);

const initialState = {
  data: null,
  loading: false,
  error: null
};
export const contactUsSlice = createSlice({
  name: 'fetchContactUsInitialData',
  initialState,
  reducers: {
    updateDemoRequestState: (state) => {
      state.data = {
        ...state.data,
        contactUsDetails: {
          demoReqest: {
            submitted: true,
            date: new Date().toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })
          }
        }
      };
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactUsInitialData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContactUsInitialData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = { ...action.payload.data };
      })
      .addCase(fetchContactUsInitialData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { updateDemoRequestState, resetState } = contactUsSlice.actions;

export const getContactUsData = (state) => state.contactUs?.data;
export const getContactUsLoading = (state) => state.contactUs?.loading;
export const getUserDetailsError = (state) => state.contactUs?.error;

export const getUserDetails = createSelector(
  getStoreUserDetails,
  getContactUsData,
  (storeUserDetails, contactUsData) =>
    storeUserDetails || contactUsData?.userDetails
);

export const getContactUsDetails = createSelector(
  getStoreContactUsDetails,
  getContactUsData,
  (storeUserDetails, contactUsData) =>
    storeUserDetails || contactUsData?.contactUsDetails
);

export const getDemoRequestDetails = createSelector(
  getContactUsDetails,
  getContactUsData,
  (contactUsDetails, contactUsData) =>
    contactUsData?.contactUsDetails?.demoReqest || contactUsDetails?.demoReqest
);

export const getLoadingState = createSelector(
  getStoreLoading,
  getStoreUserDetails,
  getContactUsLoading,
  (storeLoading, storeUserDetails, contactUsLoading) => {
    if (storeLoading) return true;
    if (storeUserDetails?.name && storeUserDetails?.email) return false;
    return contactUsLoading;
  }
);

export const getAuthenticityToken = createSelector(
  getStoreAuthenticityToken,
  getContactUsData,
  (storeAuthenticityToken, contactUsData) =>
    storeAuthenticityToken || contactUsData?.meta?.authenticity_token
);

export const getCheckEmailDomain = createSelector(
  getStoreCheckEmailDomain,
  getContactUsData,
  (storeCheckEmailDomain, productData) =>
    // If store Check Email Domain is null or undefined, it returns productData value
    storeCheckEmailDomain ?? productData?.checkEmailDomain?.enabled
);
