import React from 'react';
import { Provider } from 'react-redux';
import {
  useErrorBoundary,
  withErrorBoundary
} from '@browserstack/design-stack';

import { store } from '../../redux/store';

import ContactUsModalComponent from './components/ContactUsModalComponent';
import {
  ContactUsModalDefaultProps,
  ContactUsModalPropTypes
} from './utils/index';

const ContactUsModal = (props) => {
  const [error] = useErrorBoundary();

  if (error) return null;

  return (
    <Provider store={store}>
      <ContactUsModalComponent {...props} />
    </Provider>
  );
};

ContactUsModal.propTypes = ContactUsModalPropTypes;

ContactUsModal.defaultProps = ContactUsModalDefaultProps;

export default withErrorBoundary(ContactUsModal);
