export const PRODUCT_TITLE_DESC_MAPPING = {
  'accessibility-testing': {
    productNameToDisplay: 'Accessibility Testing',
    subHeading:
      'Onboard as many team members as you need to efficiently Test & Monitor your website’s accessibility. Enjoy faster scans, real screen readers, and automatic daily monitoring of your entire website.',
    features: [
      'Workflow Scanner',
      'Website Scanner',
      'Assisted Test',
      'Screen Reader'
    ],
    modalSize: 'lg'
  },
  'test-observability': {
    productNameToDisplay: 'Test Observability',
    subHeading:
      'Onboard as many team members as you need to efficiently test and deep dive into your system’s performance. Benefit from AI-driven test failure analysis, your ultimate solution for detecting and resolving critical test suite issues.',
    features: [
      'Auto-detection of genuine failures',
      'AI-enabled timeline debugging',
      'Automation health tracking & alerts',
      'Clean, intuitive TestOps workflows'
    ],
    modalSize: '2xl'
  },
  'test-management': {
    productNameToDisplay: 'Test Management',
    subHeading:
      'Modern & unified way to create, manage, and track test cases and test runs. Efficiently create, oversee, and monitor test cases and runs through a contemporary and cohesive approach to testing management.',
    features: [
      'Centralized Test Case Repository',
      'Streamlined Test Run Management',
      'Powerful Jira App',
      'Rich Dashboard & Real-time Insights'
    ],
    modalSize: '2xl'
  }
};

export const PAYWALL_BANNER_REF = 'paywall-free-offer-banner';
export const ONLINE_SALES = 'online_sales';
