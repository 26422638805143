import React from 'react';
import PropTypes from 'prop-types';

const AutomationIcon = ({ iconClass }) => (
  <svg
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={iconClass}
  >
    <path
      d="m18 11.143v-2.2858h-2.4013c-0.1473-0.71535-0.4313-1.3956-0.8364-2.0033l1.7028-1.7029-1.616-1.616-1.7028 1.7029c-0.6078-0.40514-1.288-0.68919-2.0034-0.83657v-2.4013h-2.2858v2.4013c-0.71535 0.14736-1.3956 0.43137-2.0033 0.83645l-1.7029-1.7028-1.616 1.616 1.7029 1.7028c-0.40514 0.60778-0.68919 1.288-0.83657 2.0034h-2.4013v2.2858h2.4013c0.14736 0.7153 0.43137 1.3955 0.83645 2.0033l-1.7028 1.7028 1.616 1.616 1.7028-1.7028c0.60778 0.4051 1.288 0.6892 2.0034 0.8365v2.4013h2.2858v-2.4013c0.7153-0.1473 1.3955-0.4313 2.0033-0.8364l1.7028 1.7028 1.616-1.616-1.7028-1.7028c0.4051-0.6078 0.6892-1.288 0.8365-2.0034h2.4013zm-8 2.2857c-0.67811 0-1.341-0.2011-1.9048-0.5778-0.56383-0.3768-1.0033-0.9123-1.2628-1.5387-0.2595-0.6265-0.32739-1.3159-0.1951-1.981s0.45883-1.276 0.93832-1.7555c0.4795-0.47949 1.0904-0.80603 1.7555-0.93832s1.3545-0.0644 1.981 0.1951c0.6264 0.2595 1.1619 0.69895 1.5387 1.2628 0.3767 0.56382 0.5778 1.2267 0.5778 1.9048-1e-3 0.909-0.3626 1.7805-1.0053 2.4233-0.6428 0.6427-1.5143 1.0043-2.4233 1.0053z"
      fill="#184CAF"
    />
  </svg>
);

AutomationIcon.propTypes = {
  iconClass: PropTypes.string
};
AutomationIcon.defaultProps = {
  iconClass: ''
};

export default AutomationIcon;
