import { ACCOUNT_SETUP_ENABLED_PRODUCT } from './constant';

const { href } = window.location;

export const getRedirectUrl = () => window.location.href;
const shouldExclude = (excludeUrls) =>
  excludeUrls.some((substring) => href.includes(substring));
export const getRef = (product, excludeUrls) =>
  ACCOUNT_SETUP_ENABLED_PRODUCT.includes(product) && !shouldExclude(excludeUrls)
    ? 'account_setup'
    : '';
