import axios from 'axios';

import { BASE_URL } from '../utils/helper';

import { REQUEST_TIMOUT } from './contants';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: REQUEST_TIMOUT
});

export default axiosInstance;
