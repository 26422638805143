import React from 'react';
import { Provider } from 'react-redux';
import {
  useErrorBoundary,
  withErrorBoundary
} from '@browserstack/design-stack';

import { store } from '../../redux/store';

import PaywallFreeOfferModalComponent from './components/PaywallFreeOfferModalComponent';

const PaywallFreeOfferModal = (props) => {
  const [error] = useErrorBoundary();
  if (error) return null;

  return (
    <Provider store={store}>
      <PaywallFreeOfferModalComponent {...props} />
    </Provider>
  );
};

export default withErrorBoundary(PaywallFreeOfferModal);
