import { AMPLITUDE_CONFIG, EDS_CONFIG, ENV_MAPPING } from './constants';

export default {
  [ENV_MAPPING.LOCAL]: {
    AMPLITUDE_KEY: AMPLITUDE_CONFIG.LOCAL.KEY,
    ANALYTICS_KEY: 'UA-x-x',
    EDS_API_KEY: EDS_CONFIG.STAGING.KEY,
    EDS_SERVER: EDS_CONFIG.STAGING.SERVER
  },
  [ENV_MAPPING.DEMO_STAGING]: {
    AMPLITUDE_KEY: AMPLITUDE_CONFIG.STAGING.KEY,
    ANALYTICS_KEY: 'UA-x-x',
    EDS_API_KEY: EDS_CONFIG.STAGING.KEY,
    EDS_SERVER: EDS_CONFIG.STAGING.SERVER
  }
};
