import React from 'react';
import PropTypes from 'prop-types';

const AutomationFolderIcon = ({ iconClass }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={iconClass}
  >
    <path
      d="m8.3334 3.334h-5c-0.91666 0-1.6583 0.75-1.6583 1.6667l-0.00833 10c0 0.9166 0.75 1.6666 1.6667 1.6666h13.333c0.9167 0 1.6667-0.75 1.6667-1.6666v-8.3334c0-0.91667-0.75-1.6667-1.6667-1.6667h-6.6667l-1.6666-1.6667z"
      fill="#38BDF8"
    />
    <path
      d="m19 14.786h0.5v-2.5714h-1.763c-0.0847-0.2768-0.1964-0.5446-0.3336-0.7995l0.8949-0.895 0.3536-0.3535-0.3536-0.35356-1.111-1.111-0.3535-0.35355-0.3536 0.35355-0.8949 0.89494c-0.2549-0.13723-0.5227-0.24898-0.7996-0.33364v-1.763h-2.5714v1.763c-0.2768 0.08464-0.5446 0.19637-0.7995 0.33357l-0.895-0.89495-0.3535-0.35355-1.4646 1.4646-0.35355 0.35354 0.35355 0.3536 0.89494 0.8949c-0.13723 0.2549-0.24898 0.5227-0.33364 0.7996h-1.763v2.5714h1.763c0.08464 0.2768 0.19637 0.5446 0.33357 0.7995l-0.89495 0.895-0.35355 0.3535 0.35355 0.3536 1.111 1.111 0.35354 0.3535 0.3536-0.3535 0.8949-0.895c0.2549 0.1372 0.5227 0.249 0.7996 0.3337v1.763h2.5714v-1.763c0.2768-0.0847 0.5446-0.1964 0.7995-0.3336l0.895 0.8949 0.3535 0.3536 1.8181-1.8181-0.3535-0.3536-0.895-0.8949c0.1372-0.2549 0.249-0.5227 0.3337-0.7996h1.263zm-5.5001 0.5714c-0.3673 0-0.7263-0.1089-1.0317-0.3129-0.3054-0.2041-0.5434-0.4942-0.684-0.8335-0.1405-0.3394-0.1773-0.7128-0.1057-1.073 0.0717-0.3603 0.2486-0.6912 0.5083-0.9509s0.5906-0.4366 0.9509-0.5083c0.3602-0.0716 0.7336-0.0348 1.073 0.1057 0.3393 0.1406 0.6294 0.3786 0.8335 0.684 0.204 0.3054 0.3129 0.6644 0.3129 1.0317-6e-4 0.4923-0.1965 0.9644-0.5447 1.3125-0.3481 0.3482-0.8202 0.5441-1.3125 0.5447z"
      fill="#0369A1"
      stroke="#fff"
    />
    <circle cx="13.5" cy="13.5" r="1.5" fill="#fff" />
  </svg>
);

AutomationFolderIcon.propTypes = {
  iconClass: PropTypes.string
};
AutomationFolderIcon.defaultProps = {
  iconClass: ''
};

export default AutomationFolderIcon;
