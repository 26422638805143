import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea
} from '@browserstack/design-stack';
import PropTypes from 'prop-types';

import { UserDetailsProps } from '../utils';

import ContactSalesInputField from './ContactSalesInputField';

const ContactUsDemoModal = ({
  onEmailTextChange,
  email,
  onQueryTextChange,
  query,
  onGetDemoClick,
  isLoading,
  modalProps,
  userDetails,
  errorText,
  checkEmailDomain,
  isDisabled,
  onEmailFieldFocus
}) => {
  const heading = (
    <span className="flex">
      Hi&nbsp;
      <span className="inline-block truncate" style={{ maxWidth: '180px' }}>
        {userDetails?.name}
      </span>
      , get a personalized demo!
    </span>
  );

  return (
    <Modal size="lg" {...modalProps?.container}>
      <ModalHeader
        heading={heading}
        subHeading="Our experts will get in touch with you."
        {...modalProps?.header}
      />
      <ModalBody {...modalProps?.body}>
        {checkEmailDomain && (
          <ContactSalesInputField
            isLoading={isLoading}
            onEmailTextChange={onEmailTextChange}
            email={email}
            errorText={errorText}
            onFocus={onEmailFieldFocus}
          />
        )}
        <TextArea
          isMandatory
          disabled={isLoading}
          label="Your Query"
          placeholder="Tell us how we can help"
          rows={3}
          isResizable
          onChange={onQueryTextChange}
          value={query}
          maxLength="300"
        />
      </ModalBody>
      <ModalFooter position="right" {...modalProps?.footer}>
        <Button
          onClick={onGetDemoClick}
          loading={isLoading}
          disabled={isDisabled}
        >
          Get a demo
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ContactUsDemoModal.propTypes = {
  onEmailTextChange: PropTypes.func,
  email: PropTypes.string,
  onQueryTextChange: PropTypes.func,
  query: PropTypes.string,
  onGetDemoClick: PropTypes.func,
  isLoading: PropTypes.bool,
  userDetails: PropTypes.shape(UserDetailsProps),
  modalProps: PropTypes.shape({
    container: PropTypes.objectOf(PropTypes.any),
    header: PropTypes.objectOf(PropTypes.any),
    body: PropTypes.objectOf(PropTypes.any),
    footer: PropTypes.objectOf(PropTypes.any)
  }),
  errorText: PropTypes.string,
  checkEmailDomain: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEmailFieldFocus: PropTypes.func
};

ContactUsDemoModal.defaultProps = {
  onEmailTextChange: () => null,
  email: '',
  onQueryTextChange: () => null,
  query: '',
  onGetDemoClick: () => null,
  isLoading: false,
  modalProps: {},
  userDetails: {},
  errorText: '',
  checkEmailDomain: false,
  isDisabled: false,
  onEmailFieldFocus: () => null
};

export default ContactUsDemoModal;
