import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@browserstack/design-stack';
import { MdCheckCircle } from '@browserstack/design-stack-icons';
import PropTypes from 'prop-types';

import usePaywallFreeOfferModal from '../usePaywallFreeOfferModal';
import { PRODUCT_TITLE_DESC_MAPPING } from '../utils';

const PaywallFreeOfferModalComponent = (props) => {
  const { demoPurpose, onModalClose } = props;
  const { product, showModal, duration, handleClose } =
    usePaywallFreeOfferModal({ demoPurpose, onModalClose });
  const { productNameToDisplay, subHeading, features, modalSize } =
    PRODUCT_TITLE_DESC_MAPPING[product];

  return (
    <Modal
      size={modalSize || 'lg'}
      show={showModal}
      onClose={() => handleClose('close')}
    >
      <ModalHeader
        heading={`Your team has exclusive ${duration} months access to ${productNameToDisplay} - for free!`}
        subHeading={subHeading}
        handleDismissClick={() => handleClose('close')}
      />
      <ModalBody wrapperClassName="py-3">
        <div
          style={{
            borderWidth: 1,
            borderColor: '#D1D5DB',
            borderRadius: 8,
            padding: 16,
            color: '#374151'
          }}
        >
          <div className="mb-3 font-medium">Key features:</div>
          <ul className="columns-2">
            {features.map((feature) => (
              <li key={feature} className="mb-3 flex items-center">
                <MdCheckCircle className="mr-2 h-5 w-5 text-success-700" />
                <div className="text-sm">{feature}</div>
              </li>
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter position="right">
        <Button onClick={() => handleClose('Clicked Get Started')}>
          Get Started
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PaywallFreeOfferModalComponent.defaultProps = {
  demoPurpose: false,
  onModalClose: () => {}
};

PaywallFreeOfferModalComponent.propTypes = {
  demoPurpose: PropTypes.bool,
  onModalClose: PropTypes.func
};

export default PaywallFreeOfferModalComponent;
