import { useCallback, useState } from 'react';
import { useLatestRef, useMountEffect } from '@browserstack/hooks';

import { getRedirectUrl, getRef } from '../../../utils/accountSetupHelper';
import { BASE_URL } from '../../../utils/helper';
import { updateProduct } from '../slices/appSlice';
import { fetchInitialData } from '../slices/dataSlice';

export const useGrowthData = ({ product, excludeUrls = [], store }) => {
  const stateChangeListenerRef = useLatestRef(null);
  const [init, setInit] = useState(false);
  const ref = getRef(product, excludeUrls);
  const redirect = getRedirectUrl();

  function stateChangeListener() {
    const state = store.getState();
    const action = state?.growthData?.data?.accountSetupDetails?.action;
    const url = state?.growthData?.data?.accountSetupDetails?.url;
    if (!state.growthData?.loading) {
      if (action === 'redirect') {
        window.location.href = `${BASE_URL}${url}`;
      }
      setInit(true);
    }
  }

  useMountEffect(
    () => {
      stateChangeListenerRef.current = store.subscribe(stateChangeListener);
      store.dispatch(updateProduct(product));
    },
    () => {
      if (stateChangeListenerRef.current) stateChangeListenerRef.current();
    }
  );

  async function initApi() {
    return new Promise((resolve) => {
      store
        .dispatch(fetchInitialData({ product, ref, redirect }))
        .then(() => {
          if (
            store.getState().growthData?.data?.accountSetupDetails?.action !==
            'redirect'
          ) {
            resolve(store.getState().growthData?.data);
          }
        })
        .catch(() => {
          resolve();
        });
    });
  }

  const initGrowthData = useCallback(initApi, [store, product, ref, redirect]);

  return {
    init,
    initGrowthData
  };
};
