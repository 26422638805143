import axiosInstance from './axiosInstance';

const chatWidgetService = {
  getChatWidgetInitialisation: async (data) => {
    const params = {};
    let response;

    if (data.product) params.product = data.product;

    if (data.bypassCookieAuthValidation === true) {
      params.authenticationDetails = data.authenticationDetails;
      params.bypassCookieAuthValidation = data.bypassCookieAuthValidation;
      response = await axiosInstance.get('user/fresh-chat-config', {
        params: {
          bypassCookieAuthValidation: data.bypassCookieAuthValidation,
          token: data.authenticationDetails.token,
          endpoint: data.authenticationDetails.endpoint
        }
      });
    } else {
      response = await axiosInstance.get('user/fresh-chat-config');
    }

    return response.data;
  }
};

export default chatWidgetService;
