const TCM_HOSTNAME = 'test-management.bsstag.com';
const TCM_QA_HOSTNAME = 'test-management-alpha.bsstag.com';
const ACCESSIBILITY_HOSTNAME = 'accessibility.bsstag.com';
const OBSERVABILITY_HOSTNAME = 'observability-devtestops.bsstag.com';
const OBSERVABILITY_DEMO_HOSTNAME = 'observability-demo.bsstag.com';
const OBSERVABILITY_QA_HOSTNAME = 'observability-qa.bsstag.com';
const OBSERVABILITY_PREPROD_HOSTNAME = 'observability-preprod.bsstag.com';
const OBSERVABILITY_RAILS_APP_HOSTNAME = 'https://devtestops.bsstag.com';
const TURBOSCALE_HOSTNAME = 'grid-devhst.bsstag.com';
const LOCAL_HOST = 'local.bsstag.com';
const GROWTH_DEMO = 'growth-frontend.bsstag.com';
const LCNC_HOSTNAME = 'low-code.bsstag.com';
const QD_HOSTNAME = 'quality-dashboard.bsstag.com';
const QD_PREPROD_HOSTNAME = 'quality-dashboard-preprod.bsstag.com';
const APP_A11Y_QA_STAGING_HOSTNAME = 'app-accessibility.bsstag.com';
const APP_A11Y_DEV_STAGING_HOSTNAME = 'devapp-accessibility.bsstag.com';
const LCNC_PREPROD_HOSTNAME = 'low-code-preprod.bsstag.com';

const PRE_PROD_HOST_NAME = 'https://preprod.bsstag.com';

export const RAILS_APP_HOSTNAMES = {
  [TCM_HOSTNAME]: 'https://devteststack.bsstag.com',
  [TCM_QA_HOSTNAME]: 'https://devteststack.bsstag.com',
  [ACCESSIBILITY_HOSTNAME]: 'https://devaccessibility.bsstag.com',
  [OBSERVABILITY_HOSTNAME]: OBSERVABILITY_RAILS_APP_HOSTNAME,
  [OBSERVABILITY_DEMO_HOSTNAME]: OBSERVABILITY_RAILS_APP_HOSTNAME,
  [OBSERVABILITY_QA_HOSTNAME]: OBSERVABILITY_RAILS_APP_HOSTNAME,
  [OBSERVABILITY_PREPROD_HOSTNAME]: PRE_PROD_HOST_NAME,
  [TURBOSCALE_HOSTNAME]: 'https://devhst.bsstag.com',
  [LOCAL_HOST]: 'https://local.bsstag.com',
  [GROWTH_DEMO]: 'https://k8s-devos.bsstag.com',
  [LCNC_HOSTNAME]: 'https://lcnc.bsstag.com',
  [QD_HOSTNAME]: 'https://stagqd.bsstag.com',
  [QD_PREPROD_HOSTNAME]: PRE_PROD_HOST_NAME,
  [APP_A11Y_QA_STAGING_HOSTNAME]: 'https://app-a11y.bsstag.com',
  [APP_A11Y_DEV_STAGING_HOSTNAME]: 'https://dev-app-a11y.bsstag.com',
  [LCNC_PREPROD_HOSTNAME]: PRE_PROD_HOST_NAME
};

export const ACCOUNT_SETUP_ENABLED_PRODUCT = [
  'test-management',
  'test-observability',
  'accessibility-testing'
];
