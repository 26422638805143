import React from 'react';
import {
  CodeSnippet,
  CodeSnippetToolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@browserstack/design-stack';
import { ContactSalesButton } from '@browserstack/growth';

const GRTColumns = [
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Description',
    key: 'description'
  },
  {
    name: 'Default',
    key: 'default'
  },
  {
    name: '',
    key: 'action',
    cell: () => (
      <>
        <ContactSalesButton
          buttonProps={{
            label: 'Contact Sales',
            onClick: () => null
          }}
          contactSalesProps={{
            product: 'Test Observability',
            source: 'Product_TestObservability_ContactUs_CTA'
          }}
          showAsHyperLink
          hyperlinkProps={{
            label: 'Contact Sales',
            onClick: () => null
          }}
        />
      </>
    )
  }
];

const GRTRows = [
  {
    title: 'Prop',
    value: [
      {
        name: 'buttonProps',
        description: 'Bifrost Button props',
        default: 'demo',
        isAction: false
      },
      {
        name: 'contactSalesButtonProps',
        description: 'refer /contact',
        default: 'null',
        isAction: false
      },
      {
        name: 'showAsHyperLink',
        description: 'To render button as a hyperlink',
        default: 'false',
        isAction: false
      },
      {
        name: 'hyperlinkProps',
        description: 'Bifrost Hyperlink props',
        default: '{}',
        isAction: false
      }
    ]
  },
  {
    title: 'Component',
    value: [
      {
        name: 'ContactSalesButton',
        description: '-',
        default: '-',
        isAction: true
      }
    ]
  }
];

const ContactSalesModalButton = () => (
  <div className="p-5">
    <h1 className="mb-5 text-center text-xl">
      ContactSalesButton Documentation
    </h1>

    <Table>
      <TableHead wrapperClassName="bg-white">
        <TableRow>
          {GRTColumns.map((col) => (
            <TableCell key={col.key} variant="header">
              {col.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {GRTRows.map((row, idx) => (
          <React.Fragment key={row.title}>
            <TableRow>
              <TableCell
                variant="header"
                colspan={GRTColumns.length}
                wrapperClassName="bg-base-50"
              >
                {row.title}
              </TableCell>
            </TableRow>
            {row.value.map((per, perIdx) => {
              const key = idx + perIdx;
              return (
                <TableRow key={key}>
                  {GRTColumns.map((column, colIdx) => {
                    const value = per[column.key];
                    return (
                      <TableCell
                        key={column.id}
                        wrapperClassName={
                          colIdx === 0
                            ? 'text-base-900 font-medium'
                            : 'text-base-500'
                        }
                      >
                        {per.isAction ? (
                          <>{column.cell ? <>{column.cell()}</> : value}</>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>

    <div className="mt-5" />
    <CodeSnippet
      toolbar={
        <CodeSnippetToolbar
          leadingNode={
            <div className="flex items-center space-x-2">
              ContactSalesButton
            </div>
          }
        />
      }
      language="react/jsx"
      code={`import { ContactSalesButton } from "@browserstack/growth;

      <ContactSalesButton
        buttonProps={{
          label: 'Contact Sales',
          onClick: () => console.log('hello')
        }}
        contactSalesProps={{
          product: 'Test Observability',
          source: 'test-management-dashboard-demo-lead'
        }}
      />`}
    />
  </div>
);

export default ContactSalesModalButton;
