import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { useGrowthStore } from '@browserstack/growth';
import { useMountEffect } from '@browserstack/hooks';
import { initLogger } from '@browserstack/utils';

import Layout from './components/Layout';
import ChatWidget from './features/chatWidget';
import ContactSalesModalButton from './features/ContactSalesModalButton';
import ContactUs from './features/contactUs';
import DemoButton from './features/DemoButton';
import PaywallFreeOffer from './features/PaywallFreeOffer';
import { getEnvConfig } from './common';
import configKeys from './keys';

const App = () => {
  const [loaded, setLoaded] = useState(false);
  const { initGrowthData } = useGrowthStore({ product: 'test-observability' });

  const envConfig = getEnvConfig();

  const Routes = useRoutes([
    {
      element: <Layout />,
      path: '/:vid'
    },
    {
      element: <ChatWidget />,
      path: '/:vid/freshchat'
    },
    {
      element: <ContactUs />,
      path: '/:vid/contact'
    },
    {
      element: <DemoButton />,
      path: '/:vid/get-demo'
    },
    {
      element: <ContactSalesModalButton />,
      path: '/:vid/contact-sales-modal'
    },
    {
      element: <PaywallFreeOffer />,
      path: '/:vid/paywall-free-offer'
    }
  ]);

  useMountEffect(() => {
    initGrowthData().finally(() => {
      const keys = {
        amplitudeKey: configKeys[envConfig.name].AMPLITUDE_KEY,
        analyticsKey: configKeys[envConfig.name].ANALYTICS_KEY,
        amplitudeConfig: {
          key: configKeys[envConfig.name].AMPLITUDE_KEY,
          userData: {
            user_id: '1828'
          },
          groupData: {
            group_id: '1828'
          }
        },
        EDSDetails: {
          userDetails: {
            user_id: '1828',
            group_id: '1828'
          },
          config: {
            server: configKeys[envConfig.name].EDS_SERVER,
            port: '443',
            apiKey: configKeys[envConfig.name].EDS_API_KEY
          }
        }
      };
      initLogger(keys);
    });
    setLoaded(true);
  });

  return loaded ? (
    <div>
      <h1>Live</h1>
      {Routes}
    </div>
  ) : null;
};

export default App;
