import envConfig from './config';
import { ENV_MAPPING } from './constants';

export const getEnvConfig = () => {
  const { hostname } = window.location;
  const subdomain = hostname.split('.')[0];
  if (subdomain === 'growth-frontend' && hostname.includes('bsstag')) {
    return envConfig[ENV_MAPPING.DEMO_STAGING];
  }
  return envConfig[ENV_MAPPING.DEMO_STAGING];
};
