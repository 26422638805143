import axiosInstance from './axiosInstance';

const initDataServices = {
  getInitData: async (payload) => {
    const response = await axiosInstance.get('/user/growth-details', {
      headers: {
        Accept: 'application/json'
      },
      params: payload,
      timeout: 2000
    });

    return response.data;
  }
};

export default initDataServices;
