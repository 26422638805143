import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  Button,
  useErrorBoundary,
  withErrorBoundary
} from '@browserstack/design-stack';
import { twClassNames } from '@browserstack/utils';
import PropTypes from 'prop-types';

import { store } from '../../redux/store';

import { fetchChatWidgetInitialData } from './slices/chatWidgetSlices';
import {
  ChatIconSVG,
  FRESHCHAT_WIDGET_CUSTOM_BUTTON_ID,
  handleScriptLoad,
  hideWidget,
  showWidget
} from './utils/index';

export const toggleChatWidget = (status) => {
  if (status === true) {
    showWidget();
  } else {
    hideWidget();
  }
};

const ChatWidgetComponent = ({
  direction,
  product,
  bypassCookieAuthValidation,
  authenticationDetails
}) => {
  const [freshchatLoad, setFreshChatLoad] = useState(false);
  const chatWidget = useSelector((state) => state.chatWidget?.data);
  const dispatch = useDispatch();

  const showChatWindow = () => {
    if (window.fcWidget && !window.fcWidget.isOpen()) {
      showWidget();
    }
  };

  useEffect(() => {
    const data = {
      product,
      bypassCookieAuthValidation,
      authenticationDetails
    };
    dispatch(fetchChatWidgetInitialData(data));
  }, [dispatch, product, bypassCookieAuthValidation, authenticationDetails]);

  useEffect(() => {
    if (chatWidget)
      handleScriptLoad(chatWidget, showChatWindow, direction, setFreshChatLoad);
  }, [chatWidget, direction]);

  return (
    <>
      {chatWidget?.custom_widget && freshchatLoad && (
        <Button
          id={FRESHCHAT_WIDGET_CUSTOM_BUTTON_ID}
          onClick={() => {
            hideWidget();
            window.fcWidget?.open();
          }}
          wrapperClassName={twClassNames(
            'fixed bottom-6 rounded-none p-3 shadow-md',
            {
              'left-6': direction === 'left',
              'right-6': direction === 'right'
            }
          )}
        >
          <div className="flex items-center justify-between text-sm">
            <ChatIconSVG />
            Talk to an Expert
          </div>
        </Button>
      )}
    </>
  );
};

const ChatWidget = (props) => {
  const [error] = useErrorBoundary();

  if (error) return null;

  return (
    <Provider store={store}>
      <ChatWidgetComponent {...props} />
    </Provider>
  );
};

ChatWidget.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  product: PropTypes.string,
  bypassCookieAuthValidation: PropTypes.bool,
  authValidationParams: PropTypes.objectOf(PropTypes.any)
};

ChatWidget.defaultProps = {
  direction: 'right',
  product: '',
  bypassCookieAuthValidation: false,
  authenticationDetails: {
    token: '',
    endpoint: ''
  }
};

ChatWidgetComponent.propTypes = ChatWidget.propTypes;

ChatWidgetComponent.defaultProps = ChatWidget.defaultProps;

export default withErrorBoundary(ChatWidget);
