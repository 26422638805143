// APP
export const getContactUsModalState = (state, instanceId = 'default') =>
  !!state.growthApp?.contactUsModalVisible[instanceId];
export const getStoreProduct = (state) => state.growthApp?.product;

// DATA
export const getStoreError = (state) => state.growthData?.error;
export const getStoreLoading = (state) => state.growthData?.loading;
export const getStoreUserDetails = (state) =>
  state.growthData?.data?.userDetails;
export const getStoreUserRole = (state) =>
  state.growthData?.data?.userDetails?.userRole;
export const getStoreContactUsDetails = (state) =>
  state.growthData?.data?.contactUsDetails;
export const getStoreAuthenticityToken = (state) =>
  state.growthData?.data?.meta?.authenticity_token;
export const getStoreCheckEmailDomain = (state) =>
  state.growthData?.data?.checkEmailDomain?.enabled;
