import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '@browserstack/utils';

import contactUsService from '../../../api/contactUsServices';
import {
  getContactUsModalState,
  getStoreProduct,
  toggleContactUsModal
} from '../../ServiceLayer';
import {
  CONTACT_MODAL_SOURCE_MAP,
  CONTACT_MODAL_VARIANTS,
  DEFAULT_API_STATE,
  MAX_RETRY_COUNT,
  PREFILL_QUERY,
  QUERY_TYPE_MAP,
  SUBMIT_MODAL_TYPE
} from '../consts';
import {
  fetchContactUsInitialData,
  getAuthenticityToken,
  getCheckEmailDomain,
  getDemoRequestDetails,
  getLoadingState,
  getUserDetails,
  getUserDetailsError,
  resetState,
  updateDemoRequestState
} from '../slices/contactUsSlices';

export const useContactUs = ({
  instanceId,
  variant,
  product,
  userDetails,
  source,
  onSuccess,
  onError,
  onClose
}) => {
  const dispatch = useDispatch();
  const prefillData = useSelector(getUserDetails);
  const prefillApiError = useSelector(getUserDetailsError);
  const contactUsModalState = useSelector((state) =>
    getContactUsModalState(state, instanceId)
  );
  const demoRequestDetails = useSelector(getDemoRequestDetails);
  const authenticityToken = useSelector(getAuthenticityToken);
  const checkEmailDomain = useSelector(getCheckEmailDomain);
  const productName = useSelector(getStoreProduct);
  const loading = useSelector(getLoadingState);
  const [email, setEmail] = useState(prefillData?.email);
  const [query, setQuery] = useState(
    userDetails?.message || PREFILL_QUERY(product)[variant]
  );
  const [isDisabled, setDisabled] = useState(false);
  const [apiState, setApiState] = useState(DEFAULT_API_STATE);
  const [retryCount, setRetryCount] = useState(MAX_RETRY_COUNT);

  const userData = { ...userDetails, ...prefillData };

  const storeEvent = useCallback(
    (event = 'LoadContactForm', params = {}) => {
      logEvent([], 'online_sales', event, {
        source: `${source}-modal`,
        ModalType: 'concise',
        ProductTypeSubmitted: product,
        team: 'online_sales',
        url: window.location.href,
        user_type: userData.userType,
        ...params
      });
    },
    [product, source, userData.userType]
  );

  const updateApiState = useCallback(
    (state) => {
      if (state.type === SUBMIT_MODAL_TYPE.SUCCESS) {
        state.error = false;
        state.errorMessage = '';
        storeEvent('SubmitContactForm', { FormSubmitSuccess: true });
        onSuccess();
      }
      if (
        state.type === SUBMIT_MODAL_TYPE.RETRY ||
        state.type === SUBMIT_MODAL_TYPE.INPUT_ERROR
      ) {
        state.error = true;
        state.successMessage = '';
        storeEvent('SubmitContactForm', { FormSubmitSuccess: false });
        onError();
      }
      if (state.type) {
        state.loading = false;
      }
      setApiState((prev) => ({ ...prev, ...state }));
    },
    [onSuccess, onError, storeEvent]
  );

  const handleDismissClick = useCallback(() => {
    setApiState(DEFAULT_API_STATE);
    if (typeof demoRequestDetails === 'undefined') dispatch(resetState());
    dispatch(toggleContactUsModal({ instanceId, toggle: false }));
    if (apiState?.type === SUBMIT_MODAL_TYPE.SUCCESS) {
      dispatch(updateDemoRequestState());
    }
  }, [apiState?.type, demoRequestDetails, instanceId, dispatch]);

  useEffect(() => {
    if (contactUsModalState && !prefillData && !prefillApiError) {
      dispatch(fetchContactUsInitialData(productName));
    } else if (
      variant === CONTACT_MODAL_VARIANTS.DEMO &&
      demoRequestDetails?.submitted
    ) {
      updateApiState({ type: SUBMIT_MODAL_TYPE.DUPLICATE });
    }
  }, [
    prefillData,
    contactUsModalState,
    demoRequestDetails,
    prefillApiError,
    productName,
    variant,
    updateApiState,
    dispatch
  ]);

  useEffect(() => {
    if (variant === CONTACT_MODAL_VARIANTS.DEMO && prefillApiError) {
      const signedOutRequest = prefillApiError.includes(401);
      if (signedOutRequest) {
        // TODO: refactor hardcoded url -> method to get env specific url
        window.open(
          `https://browserstack.com/contact?&ref=${source}`,
          '_blank',
          'noopener,noreferrer'
        );
        handleDismissClick();
      } else {
        updateApiState({
          type: SUBMIT_MODAL_TYPE.ERROR
        });
      }
    }
  }, [
    prefillApiError,
    variant,
    source,
    handleDismissClick,
    updateApiState,
    dispatch
  ]);

  useEffect(() => {
    // Need this because default value is not getting set for email on component load
    setEmail(prefillData?.email);
    if (!contactUsModalState) {
      onClose();
    } else {
      storeEvent();
    }
  }, [contactUsModalState, onClose, storeEvent, prefillData, isDisabled]);

  const createLead = async () => {
    try {
      const resp = await contactUsService.createContact({
        name: userData.name,
        email,
        query_type: QUERY_TYPE_MAP[userData.queryrelatedTo],
        company: userData.company,
        message: query,
        ref: source,
        product: productName,
        authenticity_token: authenticityToken,
        send_email: !!userData.sendCopy,
        request_type: 'Sales Enquiry',
        cta_ref: 'Support Pages',
        web_utm_source: `${source}-modal`,
        lead_source: CONTACT_MODAL_SOURCE_MAP[variant],
        check_email_domain: checkEmailDomain ?? false
      });
      return Promise.resolve(resp);
    } catch (error) {
      return Promise.resolve(false);
    }
  };

  // Event handlers
  const handleEmailTextChange = (e) => {
    setEmail(e.target.value);
    setDisabled(e.target.value === '');
  };

  const onEmailFieldFocus = () => {
    storeEvent('CsfInteractedWithEmailField');
  };

  const handleQueryTextChange = (e) => {
    if (e.target.value !== query) setQuery(e.target.value);
    setDisabled(e.target.value === '');
  };

  const handlePositiveCTAClick = async () => {
    updateApiState({ loading: true });
    let resp;

    if (apiState.type && retryCount > 1) setRetryCount((count) => count - 1);
    if (
      variant === CONTACT_MODAL_VARIANTS.DEMO ||
      variant === CONTACT_MODAL_VARIANTS.CONTACT_SALES_MODAL
    ) {
      resp = await createLead();
    } // Extend this block to handle new variants
    if (resp && resp.success) {
      updateApiState({
        type: SUBMIT_MODAL_TYPE.SUCCESS,
        inputError: ''
      });
    } else if (resp && !resp.success && resp.field === 'business_email') {
      // INPUT ERROR BLOCK
      updateApiState({
        loading: false,
        type: SUBMIT_MODAL_TYPE.INPUT_ERROR,
        inputError: resp.message
      });
    } else {
      // RETRY BLOCK
      updateApiState({
        type:
          retryCount === 1 ? SUBMIT_MODAL_TYPE.ERROR : SUBMIT_MODAL_TYPE.RETRY,
        inputError: ''
      });
    }
  };

  return {
    showModal: contactUsModalState,
    email,
    query,
    apiState,
    userData,
    demoRequestDetails,
    loading,
    handleEmailTextChange,
    onEmailFieldFocus,
    handleQueryTextChange,
    handlePositiveCTAClick,
    handleDismissClick,
    storeEvent,
    checkEmailDomain,
    isDisabled
  };
};
