export const ENV_MAPPING = {
  LOCAL: 'local',
  DEMO_STAGING: 'growth-frontend'
};

export const EDS_CONFIG = {
  STAGING: {
    SERVER: 'edsstaging.bsstag.com',
    KEY: 'abcdef'
  },
  PRODUCTION: {
    SERVER: 'eds.browserstack.com',
    KEY: '5PJymLNdWrOwzQNC7J6SXBuUFQGWq4Vuw'
  }
};

export const AMPLITUDE_CONFIG = {
  LOCAL: {
    KEY: 'abcd'
  },
  STAGING: {
    KEY: '4659658140f3f8bf6dd73bb8e78293c1'
  }
};
