import ChatWidget, { toggleChatWidget } from './modules/ChatWidget/index';
import ContactSalesButton from './modules/ContactUsModal/components/ContactSalesButton';
import GetDemoButton from './modules/ContactUsModal/components/GetDemoButton';
import ContactUsModal from './modules/ContactUsModal/index';
import PaywallFreeOfferModal from './modules/PaywallFreeOfferModal';
import { toggleContactUsModal } from './modules/ServiceLayer';
import { useGrowthData } from './modules/ServiceLayer/hooks/useGrowthData';
import { store } from './redux/store';

const useGrowthStore = (args) => useGrowthData({ ...args, store });
const showContactUsModal = (args) => store.dispatch(toggleContactUsModal(args));

export {
  ChatWidget,
  ContactSalesButton,
  ContactUsModal,
  GetDemoButton,
  PaywallFreeOfferModal,
  showContactUsModal,
  toggleChatWidget,
  useGrowthStore
};
