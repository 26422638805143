import axiosInstance from './axiosInstance';

const commonService = {
  createUserProperty: async (keysToAdd, params = {}) => {
    const data = {
      keys_to_add: keysToAdd,
      ...params
    };
    const response = await axiosInstance.post('user/add-user-properties', data);
    return response.data;
  }
};

export default commonService;
