import React from 'react';
import { InputField } from '@browserstack/design-stack';
import PropTypes from 'prop-types';

const ContactSalesInputField = ({
  isLoading,
  onEmailTextChange,
  email,
  errorText,
  onFocus
}) => (
  <>
    <InputField
      isMandatory
      disabled={isLoading}
      label="Business email"
      placeholder="name@company.com"
      onChange={onEmailTextChange}
      defaultValue={email}
      aria-describedby=""
      id="csf-email"
      errorText={errorText || ''}
      onFocus={onFocus}
    />
    <div className="mb-4" />
  </>
);

ContactSalesInputField.propTypes = {
  isLoading: PropTypes.bool,
  onEmailTextChange: PropTypes.func,
  email: PropTypes.string,
  errorText: PropTypes.string,
  onFocus: PropTypes.func
};

ContactSalesInputField.defaultProps = {
  isLoading: false,
  onEmailTextChange: () => null,
  email: '',
  errorText: '',
  onFocus: () => null
};

export default ContactSalesInputField;
