import { bool, func, instanceOf, oneOfType, shape } from 'prop-types';

import useGetFocusElementsAndTrap from './useGetFocusElementsAndTrap';

const GetFocusElementsAndTrap = ({ parentRef, reTrapFocus }) => {
  useGetFocusElementsAndTrap(parentRef, reTrapFocus);
  return null;
};

GetFocusElementsAndTrap.propTypes = {
  parentRef: oneOfType([func, shape({ current: instanceOf(Element) })])
    .isRequired,
  reTrapFocus: shape({ shouldReTrapFocus: bool, shouldFocusFirstElement: bool })
};

GetFocusElementsAndTrap.defaultProps = {
  reTrapFocus: { shouldReTrapFocus: false, shouldFocusFirstElement: false }
};

export default GetFocusElementsAndTrap;
