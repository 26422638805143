import React from 'react';
import {
  CodeSnippet,
  CodeSnippetToolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@browserstack/design-stack';
import { PaywallFreeOfferModal } from '@browserstack/growth';

const GRTColumns = [
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Description',
    key: 'description'
  },
  {
    name: 'Default',
    key: 'default'
  }
];

const GRTRows = [
  {
    title: 'Prop',
    value: [
      {
        name: 'product',
        description: 'Product Name - used in header title message',
        default: 'null',
        isAction: false
      }
    ]
  }
];

const PaywallFreeOffer = () => (
  <div className="p-5">
    <h1 className="mb-5 text-center text-xl">
      PaywallFreeOffer Documentation and Demo
    </h1>

    <PaywallFreeOfferModal demoPurpose />
    <Table>
      <TableHead wrapperClassName="bg-white">
        <TableRow>
          {GRTColumns.map((col) => (
            <TableCell key={col.key} variant="header">
              {col.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {GRTRows.map((row, idx) => (
          <React.Fragment key={row.title}>
            <TableRow>
              <TableCell
                variant="header"
                colspan={GRTColumns.length}
                wrapperClassName="bg-base-50"
              >
                {row.title}
              </TableCell>
            </TableRow>
            {row.value.map((per, perIdx) => {
              const key = idx + perIdx;
              return (
                <TableRow key={key}>
                  {GRTColumns.map((column, colIdx) => {
                    const value = per[column.key];
                    return (
                      <TableCell
                        key={column.id}
                        wrapperClassName={
                          colIdx === 0
                            ? 'text-base-900 font-medium'
                            : 'text-base-500'
                        }
                      >
                        {per.isAction ? (
                          <>{column.cell ? <>{column.cell()}</> : value}</>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>

    <div className="mt-5" />
    <CodeSnippet
      toolbar={
        <CodeSnippetToolbar
          leadingNode={
            <div className="flex items-center space-x-2">
              PaywallFreeOffer Modal
            </div>
          }
        />
      }
      language="react/jsx"
      code={`import { PaywallFreeOfferModal } from "@browserstack/growth;

<PaywallFreeOfferModal />`}
    />
  </div>
);

export default PaywallFreeOffer;
