import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from '@browserstack/design-stack';
import {
  CheckIcon,
  MdWarningAmber,
  XMarkIcon
} from '@browserstack/design-stack-icons';
import { twClassNames } from '@browserstack/utils';
import PropTypes from 'prop-types';

import { SUBMIT_MODAL_TYPE } from '../consts';
import { DemoRequestDetails, UserDetailsProps } from '../utils';

const ContactUsSubmitModal = ({
  type,
  onRetryClick,
  isLoading,
  onDismiss,
  userDetails,
  demoRequestDetails
}) => {
  const ErrorIcon = (
    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-danger-100">
      <MdWarningAmber className="h-6 w-6 text-danger-600" aria-hidden="true" />
    </div>
  );

  const SuccessIcon = (
    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-success-100">
      <CheckIcon className="h-6 w-6 text-success-600" aria-hidden="true" />
    </div>
  );

  const typeMap = {
    [SUBMIT_MODAL_TYPE.SUCCESS]: {
      icon: SuccessIcon,
      label: 'Thank You!',
      subLabel: 'You will be hearing from us soon.'
    },
    [SUBMIT_MODAL_TYPE.RETRY]: {
      icon: ErrorIcon,
      label: 'Something went wrong!',
      subLabel: 'There was an error submitting the request.'
    },
    [SUBMIT_MODAL_TYPE.ERROR]: {
      icon: ErrorIcon,
      label: 'Please try after sometime!',
      subLabel: 'We are facing issues submitting the demo request.'
    },
    [SUBMIT_MODAL_TYPE.DUPLICATE]: {
      icon: SuccessIcon,
      label: 'Thank You!',
      subLabel: `Hi ${userDetails?.name}, Your demo request was submitted on ${demoRequestDetails?.date}. You will be hearing from us soon.`
    }
  };

  const CtaMap = {
    [SUBMIT_MODAL_TYPE.RETRY]: onRetryClick,
    [SUBMIT_MODAL_TYPE.ERROR]: onDismiss
  };

  return (
    <Modal show size="lg" onOverlayClick={onDismiss}>
      <ModalBody>
        <div className="py-6">
          <button
            type="button"
            className={twClassNames(
              'absolute right-4 top-4 rounded-md bg-white text-base-400 hover:text-base-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'
            )}
            onClick={onDismiss}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon
              className="h-6 w-6 text-base-400 hover:text-base-500"
              aria-hidden="true"
            />
          </button>
          {typeMap[type].icon}
          <div className="mt-5 text-center sm:mt-5">
            <h3 as="h3" className="text-lg font-medium leading-6 text-base-900">
              {typeMap[type].label}
            </h3>
            <div className="mt-2">
              <p className="text-sm font-normal leading-5 text-base-500">
                {typeMap[type].subLabel}
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      {(type === SUBMIT_MODAL_TYPE.RETRY ||
        type === SUBMIT_MODAL_TYPE.ERROR) && (
        <ModalFooter>
          {type === SUBMIT_MODAL_TYPE.RETRY && (
            <Button size="default" fullWidth colors="white" onClick={onDismiss}>
              Dismiss
            </Button>
          )}
          <Button
            size="default"
            fullWidth
            colors="brand"
            onClick={CtaMap[type]}
            loading={isLoading}
          >
            {type === SUBMIT_MODAL_TYPE.RETRY ? 'Retry' : 'Got it'}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

ContactUsSubmitModal.propTypes = {
  type: PropTypes.oneOf(Object.keys(SUBMIT_MODAL_TYPE)),
  onRetryClick: PropTypes.func,
  isLoading: PropTypes.bool,
  onDismiss: PropTypes.func,
  userDetails: PropTypes.shape(UserDetailsProps),
  demoRequestDetails: PropTypes.shape(DemoRequestDetails)
};

ContactUsSubmitModal.defaultProps = {
  type: SUBMIT_MODAL_TYPE.SUCCESS,
  onRetryClick: () => null,
  isLoading: false,
  onDismiss: () => false,
  userDetails: {},
  demoRequestDetails: {}
};

export default ContactUsSubmitModal;
