export const SUBMIT_MODAL_TYPE = {
  SUCCESS: 'SUCCESS',
  RETRY: 'RETRY',
  ERROR: 'ERROR',
  DUPLICATE: 'DUPLICATE',
  INPUT_ERROR: 'INPUT_ERROR'
};

export const CONTACT_MODAL_VARIANTS = {
  DEMO: 'DEMO',
  CONTACT_SALES_MODAL: 'CONTACT_SALES_MODAL'
};

export const CONTACT_MODAL_SOURCE_MAP = {
  DEMO: 'Demo Request',
  CONTACT_SALES_MODAL: 'Contact Sales Form'
};

export const MAX_RETRY_COUNT = 2;

export const DEFAULT_API_STATE = {
  loading: false,
  type: null,
  email: '',
  message: '',
  inputError: ''
};

export const QUERY_ENUM = {
  SALES: 'Sales',
  BILL: 'Billing & Invoicing support',
  TECH: 'Technical support',
  OPEN_SOURCE: 'Open source',
  OTHER: 'Other'
};

export const QUERY_TYPE_MAP = {
  [QUERY_ENUM.SALES]: 1,
  [QUERY_ENUM.BILL]: 2,
  [QUERY_ENUM.TECH]: 3,
  [QUERY_ENUM.OPEN_SOURCE]: 4,
  [QUERY_ENUM.OTHER]: 5
};

export const PREFILL_QUERY = (product) => ({
  [CONTACT_MODAL_VARIANTS.DEMO]: `Hi there! I would like to get a product demo for ${product}!`,
  [CONTACT_MODAL_VARIANTS.CONTACT_SALES_MODAL]: `Hi Team, I am interested in upgrading to a ${product} plan with dedicated assistance. I would like to know more details!`
});
