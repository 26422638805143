import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea
} from '@browserstack/design-stack';
import PropTypes from 'prop-types';

import { UserDetailsProps } from '../utils';

import ContactSalesInputField from './ContactSalesInputField';

const ContactSalesModal = ({
  onEmailTextChange,
  email,
  onQueryTextChange,
  query,
  onSubmit,
  isLoading,
  modalProps,
  userDetails,
  logContactModalEvent,
  errorText,
  checkEmailDomain,
  isDisabled,
  onEmailFieldFocus
}) => {
  const heading = (
    <span className="flex">
      Hi {userDetails?.name}, Drop us your query and we&apos;ll get in touch
      with you!
    </span>
  );

  return (
    <Modal size="lg" {...modalProps?.container}>
      <ModalHeader
        heading={heading}
        subHeading="Please share some details regarding your query"
        {...modalProps?.header}
      />
      <ModalBody {...modalProps?.body} wrapperClassName="py-0">
        {checkEmailDomain && (
          <ContactSalesInputField
            isLoading={isLoading}
            onEmailTextChange={onEmailTextChange}
            email={email}
            errorText={errorText}
            onFocus={onEmailFieldFocus}
          />
        )}
        <TextArea
          isMandatory
          label="Your Query"
          placeholder="Tell us how we can help"
          rows={3}
          isResizable
          onChange={onQueryTextChange}
          value={query}
          maxLength="300"
          onFocus={() => logContactModalEvent('CsfInteractedWithMessageField')}
          disabled={isLoading}
        />
      </ModalBody>
      <ModalFooter position="right" {...modalProps?.footer}>
        <Button onClick={onSubmit} loading={isLoading} disabled={isDisabled}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ContactSalesModal.propTypes = {
  onEmailTextChange: PropTypes.func,
  email: PropTypes.string,
  onQueryTextChange: PropTypes.func,
  query: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  userDetails: PropTypes.shape(UserDetailsProps),
  modalProps: PropTypes.shape({
    container: PropTypes.objectOf(PropTypes.any),
    header: PropTypes.objectOf(PropTypes.any),
    body: PropTypes.objectOf(PropTypes.any),
    footer: PropTypes.objectOf(PropTypes.any)
  }),
  logContactModalEvent: PropTypes.func,
  errorText: PropTypes.string,
  checkEmailDomain: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEmailFieldFocus: PropTypes.func
};

ContactSalesModal.defaultProps = {
  onEmailTextChange: () => null,
  email: '',
  onQueryTextChange: () => null,
  query: '',
  onSubmit: () => null,
  isLoading: false,
  modalProps: {},
  userDetails: {},
  logContactModalEvent: () => null,
  errorText: '',
  checkEmailDomain: false,
  isDisabled: false,
  onEmailFieldFocus: () => null
};

export default ContactSalesModal;
