import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
  name: 'growthAppSlice',
  initialState: {
    contactUsModalVisible: { default: false },
    product: ''
  },
  reducers: {
    toggleContactUsModal: (state, { payload }) => {
      state.contactUsModalVisible[payload.instanceId || 'default'] =
        typeof payload.toggle !== 'undefined'
          ? payload.toggle
          : !state.contactUsModalVisible[payload.instanceId || 'default'];
    },
    updateProduct: (state, { payload }) => {
      state.product = payload;
    }
  }
});

export const { toggleContactUsModal, updateProduct } = actions;

export default reducer;
