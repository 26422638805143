import { ENV_MAPPING } from './constants';

export default {
  [ENV_MAPPING.LOCAL]: {
    name: ENV_MAPPING.LOCAL
  },
  [ENV_MAPPING.DEMO_STAGING]: {
    name: ENV_MAPPING.DEMO_STAGING
  }
};
