import React from 'react';

import { CONTACT_MODAL_VARIANTS, SUBMIT_MODAL_TYPE } from '../consts';
import { useContactUs } from '../hooks/useContactUs';
import { ContactUsModalDefaultProps, ContactUsModalPropTypes } from '../utils';

import ContactSalesModal from './ContactSalesModal';
import ContactUsDemoModal from './ContactUsDemoModal';
import ContactUsSubmitModal from './ContactUsSubmitModal';

const ContactUsModalComponent = ({
  instanceId,
  variant,
  userDetails,
  product,
  onSuccess,
  onError,
  onClose,
  source,
  modalProps
}) => {
  const {
    showModal,
    email,
    query,
    userData,
    apiState,
    loading,
    demoRequestDetails,
    handleEmailTextChange,
    onEmailFieldFocus,
    handleQueryTextChange,
    handlePositiveCTAClick,
    handleDismissClick,
    storeEvent,
    checkEmailDomain,
    isDisabled
  } = useContactUs({
    instanceId,
    variant,
    product,
    userDetails,
    source,
    onSuccess,
    onError,
    onClose
  });

  if (!showModal || loading) return null;

  const variantMap = {
    [CONTACT_MODAL_VARIANTS.DEMO]: (
      <ContactUsDemoModal
        onEmailTextChange={handleEmailTextChange}
        onEmailFieldFocus={onEmailFieldFocus}
        email={email}
        onQueryTextChange={handleQueryTextChange}
        query={query}
        onGetDemoClick={handlePositiveCTAClick}
        isLoading={apiState.loading}
        userDetails={userData}
        modalProps={{
          ...modalProps,
          container: { onOverlayClick: handleDismissClick, show: showModal },
          header: { handleDismissClick }
        }}
        errorText={apiState.inputError}
        checkEmailDomain={checkEmailDomain}
        isDisabled={isDisabled}
      />
    ),
    [CONTACT_MODAL_VARIANTS.CONTACT_SALES_MODAL]: (
      <ContactSalesModal
        onEmailTextChange={handleEmailTextChange}
        onEmailFieldFocus={onEmailFieldFocus}
        email={email}
        onQueryTextChange={handleQueryTextChange}
        query={query}
        onSubmit={handlePositiveCTAClick}
        isLoading={apiState.loading}
        userDetails={userData}
        logContactModalEvent={storeEvent}
        modalProps={{
          ...modalProps,
          container: { onOverlayClick: handleDismissClick, show: showModal },
          header: { handleDismissClick }
        }}
        errorText={apiState.inputError}
        checkEmailDomain={checkEmailDomain}
        isDisabled={isDisabled}
      />
    )
  };

  return apiState.type && apiState.type !== SUBMIT_MODAL_TYPE.INPUT_ERROR ? (
    <ContactUsSubmitModal
      type={apiState.type}
      onRetryClick={handlePositiveCTAClick}
      isLoading={apiState.loading}
      onDismiss={handleDismissClick}
      userDetails={userData}
      demoRequestDetails={demoRequestDetails}
    />
  ) : (
    variantMap[variant]
  );
};

ContactUsModalComponent.propTypes = ContactUsModalPropTypes;

ContactUsModalComponent.defaultProps = ContactUsModalDefaultProps;

export default ContactUsModalComponent;
