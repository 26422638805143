import React, { forwardRef, useRef } from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  Button,
  Hyperlink,
  useErrorBoundary,
  withErrorBoundary
} from '@browserstack/design-stack';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { store } from '../../../redux/store';
import { toggleContactUsModal } from '../../ServiceLayer';
import { CONTACT_MODAL_VARIANTS } from '../consts';
import { getContactUsLoading } from '../slices/contactUsSlices';
import { ContactUsModalPropTypes } from '../utils';

import ContactUsModalComponent from './ContactUsModalComponent';

const ContactSalesButtonComponent = forwardRef(
  (
    { buttonProps, contactSalesProps, showAsHyperLink, hyperlinkProps },
    ref
  ) => {
    const loading = useSelector(getContactUsLoading);
    const instanceIdRef = useRef(uuidv4());
    const {
      onClick: onButtonClick,
      label: buttonLabel,
      ...restButtonProps
    } = buttonProps;
    const {
      onClick: onHyperlinkClick,
      label: hyperlinkLabel,
      ...restHyperlinkProps
    } = hyperlinkProps;

    const handleClick = () => {
      if (showAsHyperLink) {
        onHyperlinkClick();
      } else {
        onButtonClick();
      }
      store.dispatch(
        toggleContactUsModal({
          toggle: true,
          instanceId: instanceIdRef.current
        })
      );
    };
    return (
      <>
        {showAsHyperLink ? (
          <Hyperlink ref={ref} onClick={handleClick} {...restHyperlinkProps}>
            {hyperlinkLabel}
          </Hyperlink>
        ) : (
          <Button
            ref={ref}
            onClick={handleClick}
            loading={loading}
            {...restButtonProps}
          >
            {buttonLabel}
          </Button>
        )}
        <ContactUsModalComponent
          instanceId={instanceIdRef.current}
          variant={CONTACT_MODAL_VARIANTS.CONTACT_SALES_MODAL}
          {...contactSalesProps}
        />
      </>
    );
  }
);

ContactSalesButtonComponent.propTypes = {
  buttonProps: PropTypes.objectOf(PropTypes.any).isRequired,
  contactSalesProps: PropTypes.shape(ContactUsModalPropTypes).isRequired,
  showAsHyperLink: PropTypes.bool,
  hyperlinkProps: PropTypes.objectOf(PropTypes.any)
};

ContactSalesButtonComponent.defaultProps = {
  showAsHyperLink: false,
  hyperlinkProps: {}
};

const ContactSalesButton = (props) => {
  const [error] = useErrorBoundary();
  if (error) return null;

  return (
    <Provider store={store}>
      <ContactSalesButtonComponent {...props} />
    </Provider>
  );
};

export default withErrorBoundary(ContactSalesButton);
