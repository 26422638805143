import React, { forwardRef, useRef } from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  Button,
  useErrorBoundary,
  withErrorBoundary
} from '@browserstack/design-stack';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { store } from '../../../redux/store';
import { toggleContactUsModal } from '../../ServiceLayer';
import { CONTACT_MODAL_VARIANTS } from '../consts';
import { getContactUsLoading } from '../slices/contactUsSlices';
import { ContactUsModalPropTypes } from '../utils';

import ContactUsModalComponent from './ContactUsModalComponent';

const GetDemoButtonComponent = forwardRef(
  ({ buttonProps, demoModalProps }, ref) => {
    const loading = useSelector(getContactUsLoading);
    const { onClick, label, ...rest } = buttonProps;
    const instanceIdRef = useRef(uuidv4());
    const handleClick = () => {
      onClick();
      store.dispatch(
        toggleContactUsModal({
          toggle: true,
          instanceId: instanceIdRef.current
        })
      );
    };
    return (
      <>
        <Button ref={ref} {...rest} onClick={handleClick} loading={loading}>
          {label}
        </Button>
        <ContactUsModalComponent
          instanceId={instanceIdRef.current}
          variant={CONTACT_MODAL_VARIANTS.DEMO}
          {...demoModalProps}
        />
      </>
    );
  }
);

GetDemoButtonComponent.propTypes = {
  buttonProps: PropTypes.objectOf(PropTypes.any).isRequired,
  demoModalProps: PropTypes.shape(ContactUsModalPropTypes).isRequired
};

const GetDemoButton = (props) => {
  const [error] = useErrorBoundary();
  if (error) return null;

  return (
    <Provider store={store}>
      <GetDemoButtonComponent {...props} />
    </Provider>
  );
};

export default withErrorBoundary(GetDemoButton);
