import React from 'react';
import { Link } from 'react-router-dom';

const LINK_CLASS_NAME = 'bg-brand-500 p-4 text-white rounded-md';

const Layout = () => (
  <>
    <div className="flex flex-col items-center">
      <h1>Growth Demo App</h1>
      <p>Please click on below buttons to redirect to particular feature</p>
    </div>
    <div className="flex space-x-3 p-5">
      <Link to="/v1/freshchat" className={LINK_CLASS_NAME}>
        Freshchat 1
      </Link>
    </div>
    <div className="flex space-x-3 p-5">
      <Link to="/v1/contact" className={LINK_CLASS_NAME}>
        Contact Us Modal 1
      </Link>
    </div>
    <div className="flex space-x-3 p-5">
      <Link to="/v1/get-demo" className={LINK_CLASS_NAME}>
        Get Demo Modal
      </Link>
    </div>
    <div className="flex space-x-3 p-5">
      <Link to="/v1/contact-sales-modal" className={LINK_CLASS_NAME}>
        Contact Sales Modal 1
      </Link>
    </div>
    <div className="flex space-x-3 p-5">
      <Link
        to="/v1/paywall-free-offer?ref=paywall-free-offer-banner"
        className={LINK_CLASS_NAME}
      >
        Paywall Free Offer Modal 1
      </Link>
    </div>
  </>
);

export default Layout;
