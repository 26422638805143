import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '@browserstack/utils';

import commonService from '../../api/commonService';
import {
  getStoreAuthenticityToken,
  getStoreProduct,
  getStoreUserRole,
  setPaywallFreeOfferData
} from '../ServiceLayer';

import {
  getPaywallFreeOfferDuration,
  getShowPaywallFreeOfferModal
} from './slices/selector';
import { ONLINE_SALES, PRODUCT_TITLE_DESC_MAPPING } from './utils';

const usePaywallFreeOfferModal = ({ demoPurpose, onModalClose }) => {
  const dispatch = useDispatch();
  const showOfferModal = useSelector(getShowPaywallFreeOfferModal);
  const duration = useSelector(getPaywallFreeOfferDuration);
  const authenticityToken = useSelector(getStoreAuthenticityToken);
  const product = useSelector(getStoreProduct);
  const userRole = useSelector(getStoreUserRole);
  const { productNameToDisplay } = PRODUCT_TITLE_DESC_MAPPING[product];

  const [showModal, setShowModal] = useState(showOfferModal);

  const handleClose = async (actionName = '') => {
    setShowModal(false);
    onModalClose(false);
    dispatch(setPaywallFreeOfferData(null));
    logEvent([], ONLINE_SALES, 'InteractedwithFreeProdModal', {
      source: `${productNameToDisplay} Dashboard`,
      action: actionName,
      team: ONLINE_SALES,
      user_role: userRole
    });
    if (!demoPurpose) {
      const keysToAdd = {
        [`removal_paywall_free_offer_modal_shown_${product.replace('-', '_')}`]: true
      };
      const configParams = {
        authenticity_token: authenticityToken
      };
      await commonService.createUserProperty(keysToAdd, configParams);
    }
  };

  return {
    product,
    showModal,
    duration,
    handleClose
  };
};

export default usePaywallFreeOfferModal;
