import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import initDataServices from '../../../api/initDataServices';

export const fetchInitialData = createAsyncThunk(
  'growthData/fetchInitialData',
  async (payload) => initDataServices.getInitData(payload)
);

const { actions, reducer } = createSlice({
  name: 'growthDataSlice',
  initialState: {
    data: {
      userDetails: null,
      accountSetupDetails: null,
      paywallFreeOfferData: null,
      checkEmailDomain: null
    },
    loading: true,
    error: null
  },
  reducers: {
    setPaywallFreeOfferData: (state, { payload }) => {
      state.data.paywallFreeOfferData = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInitialData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = { ...action.payload.data };
      })
      .addCase(fetchInitialData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { setPaywallFreeOfferData } = actions;

export default reducer;
