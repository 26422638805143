import PropTypes from 'prop-types';

import { CONTACT_MODAL_VARIANTS, QUERY_ENUM } from '../consts';

export const UserDetailsProps = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  queryrelatedTo: PropTypes.oneOf(Object.values(QUERY_ENUM)),
  message: PropTypes.string,
  sendCopy: PropTypes.bool
};

export const DemoRequestDetails = {
  submitted: PropTypes.bool,
  date: PropTypes.string
};

export const ContactUsModalPropTypes = {
  variant: PropTypes.oneOf(Object.keys(CONTACT_MODAL_VARIANTS)),
  userDetails: PropTypes.shape(UserDetailsProps),
  product: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  source: PropTypes.string,
  modalProps: PropTypes.shape({
    container: PropTypes.objectOf(PropTypes.any),
    header: PropTypes.objectOf(PropTypes.any),
    body: PropTypes.objectOf(PropTypes.any),
    footer: PropTypes.objectOf(PropTypes.any)
  }),
  instanceId: PropTypes.string
};

export const ContactUsModalDefaultProps = {
  variant: CONTACT_MODAL_VARIANTS.DEMO,
  onSuccess: () => null,
  onError: () => null,
  onClose: () => null,
  source: '',
  userDetails: { queryrelatedTo: QUERY_ENUM.SALES, sendCopy: true },
  modalProps: { container: null, header: null, body: null, footer: null },
  instanceId: 'default'
};
