import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { chatWidgetSlice } from '../modules/ChatWidget/slices/chatWidgetSlices';
import { contactUsSlice } from '../modules/ContactUsModal/slices/contactUsSlices';
import { growthAppReducer, growthDataReducer } from '../modules/ServiceLayer';

const rootReducers = combineReducers({
  chatWidget: chatWidgetSlice.reducer,
  contactUs: contactUsSlice.reducer,
  growthData: growthDataReducer,
  growthApp: growthAppReducer
});

export const store = configureStore({
  reducer: rootReducers
});
