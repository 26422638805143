import React from 'react';
import {
  Button,
  CodeSnippet,
  CodeSnippetToolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@browserstack/design-stack';
import { ContactUsModal, showContactUsModal } from '@browserstack/growth';

const GRTColumns = [
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Description',
    key: 'description'
  },
  {
    name: 'Default',
    key: 'default'
  },
  {
    name: '',
    key: 'action',
    cell: () => (
      <>
        <Button onClick={() => showContactUsModal(true)}>Show Modal</Button>
        <Button onClick={() => showContactUsModal(false)}>Hide Modal</Button>
      </>
    )
  }
];

const defaultCallback = '() => null';

const GRTRows = [
  {
    title: 'Prop',
    value: [
      {
        name: 'variant(optional)',
        description: 'Different variants of Contact Us modal',
        default: 'demo',
        isAction: false
      },
      {
        name: 'product',
        description: 'Product Name - used in teaxt area message',
        default: 'null',
        isAction: false
      },
      {
        name: 'userDetails(optional)',
        description: 'User details used in contact us modal texts',
        default: 'these data will be fetched from API. if not passed',
        isAction: false
      },
      {
        name: 'onSuccess(optional)',
        description: 'event listener for success callback',
        default: defaultCallback,
        isAction: false
      },
      {
        name: 'onError(optional)',
        description: 'event listener for error callback',
        default: defaultCallback,
        isAction: false
      },
      {
        name: 'onClose(optional)',
        description: 'event listener for close callback',
        default: defaultCallback,
        isAction: false
      },
      {
        name: 'ref',
        description: 'event listener for close callback',
        default: defaultCallback,
        isAction: false
      },
      {
        name: 'modalProps(optional)',
        description: 'biforst modal props for overriding',
        default: 'null',
        isAction: false
      }
    ]
  },
  {
    title: 'Exported Utils',
    value: [
      {
        name: 'showContactUsModal',
        description: 'This will toggle the chat widget button.',
        default: '-',
        isAction: true
      }
    ]
  }
];

const ContactUs = () => (
  <div className="p-5">
    <h1 className="mb-5 text-center text-xl">
      Contact Us DOCUMENTATION AND DEMO
    </h1>

    <ContactUsModal
      product="Test Observability"
      source="test-management-dashboard-demo-lead"
    />
    <Table>
      <TableHead wrapperClassName="bg-white">
        <TableRow>
          {GRTColumns.map((col) => (
            <TableCell key={col.key} variant="header">
              {col.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {GRTRows.map((row, idx) => (
          <React.Fragment key={row.title}>
            <TableRow>
              <TableCell
                variant="header"
                colspan={GRTColumns.length}
                wrapperClassName="bg-base-50"
              >
                {row.title}
              </TableCell>
            </TableRow>
            {row.value.map((per, perIdx) => {
              const key = idx + perIdx;
              return (
                <TableRow key={key}>
                  {GRTColumns.map((column, colIdx) => {
                    const value = per[column.key];
                    return (
                      <TableCell
                        key={column.id}
                        wrapperClassName={
                          colIdx === 0
                            ? 'text-base-900 font-medium'
                            : 'text-base-500'
                        }
                      >
                        {per.isAction ? (
                          <>{column.cell ? <>{column.cell()}</> : value}</>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>

    <div className="mt-5" />
    <CodeSnippet
      toolbar={
        <CodeSnippetToolbar
          leadingNode={
            <div className="flex items-center space-x-2">
              Contact Us Modal without renderProps
            </div>
          }
        />
      }
      language="react/jsx"
      code={`import { ContactUsModal } from "@browserstack/growth;
<ContactUsModal product = "Test Observability" source="test-management-dashboard-demo-lead"/>`}
    />

    <div className="mt-5" />
    <CodeSnippet
      toolbar={
        <CodeSnippetToolbar
          leadingNode={
            <div className="flex items-center space-x-2">
              ContactUsModal Toggle Widget
            </div>
          }
        />
      }
      language="react/jsx"
      code={`import { ContactUsModal, showContactUsModal } from "@browserstack/growth;

<ContactUsModal product = "Test Observability" source="test-management-dashboard-demo-lead"/>
<button onClick={() => showContactUsModal(true)}>Show Modal</button>
<button onClick={() => showContactUsModal(false)}>Hide Modal</button>
`}
    />
  </div>
);

export default ContactUs;
